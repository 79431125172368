<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="User ID"
            label-for="user_id"
          >
            <b-form-input
              id="user_id"
              v-model="filters['user_id']"
              placeholder="Search"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          lg="3"
        >
          <b-form-group
            label="User Type"
            label-for="user_type"
          >
            <v-select
              v-model="filters['user_type']"
              :options="userTypeOptions"
              :reduce="option => option.value"
              label="label"
              placeholder="Select User Type"
            >
              <template
                v-slot:option="option"
                class="text-truncate"
              >
                {{ option.label }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <b-row>
        <b-col>
          <div
            class="my-2 mx-2"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="tenantId"
                  :reduce="option => option.id"
                  deselect-from-dropdown
                  placeholder="Select Business"
                  :options="clients"
                  class="mb-2 mb-md-0"
                  label="company_name"
                  @input="getAuditList"
                >
                  <template v-slot:option="option">
                    {{ option.company_name }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
              >
                <JsonExcel
                  class="btn p-0"
                  :data="rows"
                  type="csv"
                  :name="`Audits - Page ${page}.xls`"
                >
                  <b-button
                    v-b-tooltip.hover.v-primary="'Download Excel - Page'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="mr-1"
                    size="sm"
                  >
                    <mdicon
                      name="Download"
                      size="13"
                    />
                  </b-button>
                </JsonExcel>
                <JsonExcel
                  class="btn p-0"
                  :fetch="getAuditListNoPagination"
                  :before-generate="__showJsonDownloadAlert"
                  type="csv"
                  name="Audits.xls"
                >
                  <b-button
                    v-b-tooltip.hover.v-primary="'Download Excel - All'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="mr-1"
                    size="sm"
                  >
                    <mdicon
                      name="DownloadMultiple"
                      size="13"
                    />
                  </b-button>
                </JsonExcel>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary="'Refresh Table'"
                  variant="outline-primary"
                  size="sm"
                  @click="onClickRefresh()"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-overlay
            id="overlay-background"
            :show="loading"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
            >
              <template #cell(user_id)="data">
                {{ data.value || '#' }}
              </template>
              <template #cell(user_type)="data">
                {{ data.value || 'System' }}
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BPagination,
  BTable,
  BOverlay,
  BFormGroup,
  BFormInput,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import JsonExcel from 'vue-json-excel'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const AuditRepository = RepositoryFactory.get('audit')
const clientRepository = RepositoryFactory.get('client')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BPagination,
    BTable,
    BOverlay,
    BFormGroup,
    BFormInput,
    BButton,
    BCardActions,
    vSelect,
    JsonExcel,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      total: 0,
      meta: {},
      loading: false,
      sort: '',
      value: 25,
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      filters: {},
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'user_id',
          label: 'User Id',
          sortable: true,
        },
        {
          key: 'user_type',
          label: 'User Type',
          sortable: true,
        },
        {
          key: 'event',
          label: 'Event',
          sortable: false,
        },
        {
          key: 'auditable_type',
          label: 'Auditable Type',
          sortable: false,
        },
        {
          key: 'auditable_id',
          label: 'Auditable Id',
          sortable: false,
        },
        {
          key: 'old_values',
          label: 'Old Values',
          sortable: false,
        },
        {
          key: 'new_values',
          label: 'New Values',
          sortable: false,
        },
        {
          key: 'url',
          label: 'Url',
          sortable: false,
        },
        {
          key: 'created_at',
          label: 'Created At',
          sortable: false,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      rows: [],
      userTypeOptions: [
        {
          label: 'Staff',
          value: 'Staff',
        },
        {
          label: 'Merchant',
          value: 'Merchant',
        },
        {
          label: 'System',
          value: 'null',
        },
      ],
      tenantId: null,
      clients: [],
    }
  },
  watch: {
    page() {
      this.getAuditList()
    },
    perPage() {
      this.getAuditList()
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
    this.getClientListNoPagination()
  },
  methods: {
    async getClientListNoPagination() {
      try {
        this.loading = true
        const { data } = (await clientRepository.getClientListNoPagination())
        this.clients = data.data
        this.clients = this.clients.map(client => {
          // eslint-disable-next-line no-param-reassign
          client.company_name = client.business_settings.company_name
          return client
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getAuditList() {
      this.loading = true
      try {
        const { data } = (await AuditRepository.getAudits(this.tenantId, this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getAuditListNoPagination() {
      try {
        const { data } = (await AuditRepository.getAuditsNoPagination(this.tenantId, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    // REQUIRED
    filterQueryUpdate() {
      if (this.tenantId) this.getAuditList()
    },
    handleChangePage(page) {
      this.page = page
      this.getAuditList()
    },
    changePage(value) {
      this.perPage = value
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.getAuditList()
      }
    },
    onClickRefresh() {
      this.getAuditList()
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>
